import React from "react"
import Layout from "../components-mobile/layout"
import Banner from "../components-mobile/home/banner"
import About from "../components-mobile/about/about"
import Address from "../components-mobile/about/address"

const Aboutmobile =()=>{
  const bannerJson = {
    title: '联每户',
    subTitle: '美好生活缔造者',
    bannerUrl: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/d8dbc2c6ed7e123875987cd5fe8dea08',
    imgUrl: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/a7594885d42f9720197a77e80615bc90',
    iconUrl: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/57c38103d74871a46671ff97144907bd'
  }

  return(
    <Layout page="About">
      <Banner json={bannerJson}/>
      <About/>
      <Address/>
    </Layout>
  )
}

export default Aboutmobile