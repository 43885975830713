import React from 'react'
import styles from './index.module.css'

const About = () => {
  return (
    <div className={styles.about_mobile}>
      <div className={styles.about_title}>关于联每户</div>
      <div className={styles.about_subTitle}>
        杭州联每户网络科技有限公司（简称联每户）是由中天美好生活服务集团有限公司与上海庆科信息技术有限公司投资设立，公司成立于2020年，致力于为社区物业、业主、合作商提供整套社区生活服务解决方案。通过APP研发、物联网、云技术、大数据及人工智能的创新科技，实现社区数字化、线上化、数据化、智慧化，持续赋能物业公司和服务业，为社区家庭提供更快捷更满意的增值服务，创领全新的智慧社区新体验。
      </div>
      <div className={styles.about_subTitle}>
        通过联每户APP，可以享受基础的物业服务，如社区通行、物业缴费、社区公告、社区报事；还有基于社区周边的信息服务，如商家优惠信息、政务信息、交通信息、民生信息，以及基于邻居互动的邻聚版块；此外，联每户APP还可提供优选商城、精致服务、甄选旅游等品质生活服务。
      </div>
      <img
        className={styles.about_img1}
        src="http://s.lianmeihu.com/x-uploader/image/2021-07-15/18c3a7461cbe92fc9c239c34a9f845ad"
        alt=""
      />
    </div>
  )
}

export default About