import React, { useEffect } from "react"
import styles from "./index.module.css"
import QQMap from 'qqmap'

const Address =()=>{
  //地图初始化方法
  const initQQMap = () => {
    // console.log('初始化QQ 地图')
    //设置中心坐标
    let tarLat = 30.275638;
    let tarLng = 119.997054;
    //初始化地图
    QQMap.init('7YWBZ-OE26J-DPPF7-KYWVL-QBZBJ-F7FLC', () => {
      //初始化经纬度，最开始的点
      let myLatlng = new window.qq.maps.LatLng(tarLat, tarLng);
      //设置地图属性
      let myOptions = {
        zoom: 13,
        center: myLatlng,
        mapStyleId: 'style 1 经典',
        mapTypeId: window.qq.maps.MapTypeId.ROADMAP,
      };
      //创建地图，绑定dom
      const map = new window.qq.maps.Map(
        document.getElementById('address-container'),
        myOptions
      );
      //给定位的位置添加文本标注
      
      
      new window.qq.maps.Label({
        position: myLatlng,
        map: map,
        content:'<div style="color: #1296db;position: absolute;left: -38px;top:-80px;border: 1px solid #ccc;border-radius: 5px;background: #fff;padding: 8px;font-size:20px">联每户</div>'
      });
      //给定位的位置添加图片标注
      new window.qq.maps.Marker({
        position: myLatlng,
        map: map
      });
      
      
    })
    


  }

  useEffect(() => {
    setTimeout(()=>{

      initQQMap()
    },300)
    return () => {

    }
  }, [])
  return(
    <div className={styles.address_mobile}>
      <div className={styles.address_title}>联系我们</div>
      <div className={styles.address_img1} id='address-container'>

      </div>
    </div>
  )
}

export default Address